<template lang="pug">
.statistics
  v-container
    v-row.mt-10
      v-col(cols="12")
        h5.normal_title_h5 隊內排名
    v-row.mt-8
      v-col.position-relative(
        cols="12",
        sm="6",
        md="4",
        v-for="(rankingList, index) in playersRankingLists",
        :key="index"
      )
        players-ranking-card(
          :playersRankingList="rankingList",
          @playerClick="playerClick"
        )
  v-container.ranking_league_bar
    v-row.mt-10
      v-col(cols="12")
        h5.normal_title_h5 聯盟排名
    v-row(align="end")
      v-col.offset-1.offset-sm-0.offset-md-1(cols="10", sm="6", md="5")
        high-chart-polar(
          v-if="polarObject.averagePercentList.length > 0",
          :personalList="polarObject.personalList",
          :personalPercentList="polarObject.personalPercentList",
          :averageList="polarObject.averageList",
          :averagePercentList="polarObject.averagePercentList",
          :bestList="polarObject.bestList",
          :personalSeriesTitle="'隊伍'"
        )
      v-col.offset-1.offset-sm-0.offset-md-0.offset-lg-1.mt-8.mt-sm-0(
        cols="10",
        sm="5",
        md="5",
        lg="4",
        xl="3"
      )
        position-field(
          width="425px",
          :positionList="shotPositionList",
          :mainColor="'#487AA4'",
          :subColor="'#96B9DC'",
          @selectPosition="positionClick"
        )

    v-row.mt-15
      v-col.px-5(
        cols="12",
        md="6",
        v-for="(barRankingList, index) in barRankingLists",
        :key="index"
      )
        h5.normal_title_h5.text-center {{ barRankingList.title }}
        .bar.d-flex.align-center.mt-5(
          v-for="(ranking, rankingIndex) in barRankingList.rankings",
          :key="rankingIndex"
        )
          .title.text-body-1.font-weight-bold.d-inline-block.ml-4 {{ ranking.title }}
          .ranking.text-center.ranking_mark(
            :class="[ranking.ranking <= 3 ? 'text_secondary' : 'lightGray']"
          ) {{ getRankText(ranking.ranking) }}
          percentage-bar(
            :value="ranking.value",
            :deno="ranking.deno",
            :percent="ranking.percent"
          )
  v-container.table_container
    v-row.justify-space-between
      v-col(cols="auto")
        h5.normal_title_h5 球隊數據
    v-row.table_row
      v-col.table_col.table.position-relative.mt-5(cols="12")
        table(v-if="teamSeasonAllData.info")
          thead.top_head
            top-head(category="Statistics")
          thead.bottom_head
            bottom-head(category="Statistics")

          tbody
            sticky-column.bdl.bdr2(
              style="width: 100px; min-width: 100px",
              :offset="0",
              title="整季"
            )
            td.bdr {{ teamSeasonAllData.info.record_matches }}
            td(
              @click="dataClick(game, 'pts', '得分')",
              :class="[teamSeasonAllData.box.avg_pts > 0 ? 'link_deep' : 'gray_out']"
            ) {{ teamSeasonAllData.box.avg_pts.toFixed(1) }}
            td(
              @click="dataClick(game, 'reb', '籃板')",
              :class="[teamSeasonAllData.box.avg_reb > 0 ? 'link_deep' : 'gray_out']"
            ) {{ teamSeasonAllData.box.avg_reb.toFixed(1) }}
            td(
              @click="dataClick(game, 'ast', '助攻')",
              :class="[teamSeasonAllData.box.avg_ast > 0 ? 'link_deep' : 'gray_out']"
            ) {{ teamSeasonAllData.box.avg_ast.toFixed(1) }}
            td(
              @click="dataClick(game, 'stl', '抄截')",
              :class="[teamSeasonAllData.box.avg_stl > 0 ? 'link_deep' : 'gray_out']"
            ) {{ teamSeasonAllData.box.avg_stl.toFixed(1) }}
            td(
              @click="dataClick(game, 'blk', '阻攻')",
              :class="[teamSeasonAllData.box.avg_blk > 0 ? 'link_deep' : 'gray_out']"
            ) {{ teamSeasonAllData.box.avg_blk.toFixed(1) }}

            td(
              @click="dataClick(game, 'fg_m', '投籃命中')",
              :class="[teamSeasonAllData.box.avg_fgm > 0 ? 'link' : 'gray_out']"
            ) {{ teamSeasonAllData.box.avg_fgm.toFixed(1) }}
            td(
              @click="dataClick(game, 'fg_a', '投籃出手')",
              :class="[teamSeasonAllData.box.avg_fga > 0 ? 'link' : 'gray_out']"
            ) {{ teamSeasonAllData.box.avg_fga.toFixed(1) }}
            td.bdr {{ (teamSeasonAllData.box.avg_fg_percent * 100).toFixed(1) + '%' }}

            td(
              @click="dataClick(game, 'two_pts_m', '兩分命中')",
              :class="[teamSeasonAllData.box.avg_two_pt_fgm > 0 ? 'link' : 'gray_out']"
            ) {{ teamSeasonAllData.box.avg_two_pt_fgm.toFixed(1) }}
            td(
              @click="dataClick(game, 'two_pts_a', '兩分出手')",
              :class="[teamSeasonAllData.box.avg_two_pt_fga > 0 ? 'link' : 'gray_out']"
            ) {{ teamSeasonAllData.box.avg_two_pt_fga.toFixed(1) }}
            td.bdr {{ (teamSeasonAllData.box.avg_two_pt_fg_percent * 100).toFixed(1) + '%' }}

            td(
              @click="dataClick(game, 'three_pts_m', '三分命中')",
              :class="[teamSeasonAllData.box.avg_three_pt_fgm > 0 ? 'link' : 'gray_out']"
            ) {{ teamSeasonAllData.box.avg_three_pt_fgm.toFixed(1) }}
            td(
              @click="dataClick(game, 'three_pts_a', '三分出手')",
              :class="[teamSeasonAllData.box.avg_three_pt_fga > 0 ? 'link' : 'gray_out']"
            ) {{ teamSeasonAllData.box.avg_three_pt_fga.toFixed(1) }}
            td.bdr {{ (teamSeasonAllData.box.avg_three_pt_fg_percent * 100).toFixed(1) + '%' }}
            td(
              @click="dataClick(game, 'ft_m', '罰球命中')",
              :class="[teamSeasonAllData.box.avg_ftm > 0 ? 'link' : 'gray_out']"
            ) {{ teamSeasonAllData.box.avg_ftm.toFixed(1) }}
            td(
              @click="dataClick(game, 'ft_a', '罰球出手')",
              :class="[teamSeasonAllData.box.avg_fta > 0 ? 'link' : 'gray_out']"
            ) {{ teamSeasonAllData.box.avg_fta.toFixed(1) }}
            td.bdr {{ (teamSeasonAllData.box.avg_ft_percent * 100).toFixed(1) + '%' }}

            td(
              @click="dataClick(game, 'off_reb', '進攻籃板')",
              :class="[teamSeasonAllData.box.avg_off_reb > 0 ? 'link' : 'gray_out']"
            ) {{ teamSeasonAllData.box.avg_off_reb.toFixed(1) }}
            td(
              @click="dataClick(game, 'def_reb', '防守籃板')",
              :class="[teamSeasonAllData.box.avg_def_reb > 0 ? 'link' : 'gray_out']"
            ) {{ teamSeasonAllData.box.avg_def_reb.toFixed(1) }}

            td(
              @click="dataClick(game, 'tov', '失誤')",
              :class="[teamSeasonAllData.box.avg_tov > 0 ? 'link' : 'gray_out']"
            ) {{ teamSeasonAllData.box.avg_tov.toFixed(1) }}
            td(
              @click="dataClick(game, 'pf', '犯規')",
              :class="[teamSeasonAllData.box.avg_pf > 0 ? 'link' : 'gray_out']"
            ) {{ teamSeasonAllData.box.avg_pf.toFixed(1) }}
            td(
              :class="[teamSeasonAllData.box.avg_plus_minus > 0 ? '' : 'gray_out']"
            ) {{ teamSeasonAllData.box.avg_plus_minus.toFixed(1) }}
            td(:class="[teamSeasonAllData.box.avg_eff > 0 ? '' : 'gray_out']") {{ teamSeasonAllData.box.avg_eff.toFixed(1) }}
            td.bdr(
              :class="[teamSeasonAllData.box.avg_at_ratio > 0 ? '' : 'gray_out']"
            ) {{ teamSeasonAllData.box.avg_at_ratio.toFixed(1) }}

    v-row.table_row(v-show="teamSeasonDateData.length > 0")
      v-col.table_col.table.position-relative.mt-15(cols="12")
        table
          thead.top_head
            top-head(category="Statistics")
          thead.bottom_head
            bottom-head(category="Statistics")

          tbody
            box(
              v-for="(game, index) in teamSeasonDateData",
              :key="index",
              :item="game",
              :title="game.info.split_info.name",
              :showTime="false"
            )

    v-row.table_row
      v-col.table_col.table.position-relative.mt-15(cols="12")
        table
          thead.top_head
            top-head(category="Statistics")
          thead.bottom_head
            bottom-head(category="Statistics")

          tbody
            box(
              v-for="(game, index) in teamSeasonQuarterData",
              :key="index",
              :item="game",
              :title="game.info.split_info.name",
              :showTime="false"
            )

    v-row.table_row
      v-col.table_col.table.position-relative.mt-15(cols="12")
        table
          thead.top_head
            top-head(category="Statistics")
          thead.bottom_head
            bottom-head(category="Statistics")

          tbody
            box(
              v-for="(game, index) in teamSeasonWinLoseData",
              :key="index",
              :item="game",
              :title="game.info.split == 'win' ? '勝' : '負'",
              :showTime="false"
            )

    v-row.table_row
      v-col.table_col.table.position-relative.mt-15(cols="12")
        table
          thead.top_head
            top-head(category="Statistics")
          thead.bottom_head
            bottom-head(category="Statistics")

          tbody
            box(
              v-for="(game, index) in teamSeasonVSTeamData",
              :key="index",
              :item="game",
              :title="'vs. ' + game.info.split_info.name",
              :showTime="false",
              style="cursor: pointer",
              @rowClick="teamClick(game)"
            )

  v-dialog.position-relative(
    v-model="isDialogShow",
    style="background: white; height: 100%; width: 810px"
  )
    .close_btn_container
      v-icon.close_btn(@click="isDialogShow = false", large, color="black") mdi-close
    v-container.position-relative.content_container(ref="content")
      v-row(justify="center")
        .uvideo
          youtube(
            ref="youtube",
            @ready="onYTReady",
            @ended="onYTEnd",
            resize,
            fitParent,
            nocookie,
            style="width: 100%; height: 100%; position: absolute; top: 0; left: 0"
          )

          .yt_cover(v-if="coverYT")
            v-btn.play_all.cover(
              @click="playAllClick",
              rounded="",
              color="#487aa4",
              dark,
              v-ripple="false"
            )
              v-icon(left="")
                | mdi-play
              | 播放全部
      v-row(justify="space-between")
        .reference
          //- span.d-block 影片出處：
          //-   a.text_primary(
          //-     href="https://www.youtube.com/c/ssutv/videos",
          //-     target="_blank"
          //-   ) https://www.youtube.com/c/ssutv/videos
          //- span 影片版權皆歸中華民國大專院校體育總會及轉播單位所有
        v-btn.play_all(
          @click="playAllClick",
          rounded="",
          color="#487aa4",
          dark,
          v-ripple="false"
        )
          v-icon(left="")
            | mdi-play
          | 播放全部
      v-row
        span.player_name_team {{ dialogName }} | {{ dialogTeam }}
        .play_table
          .play_head
            .head.play_play
            .head.play_time 時間
            .head.play_player 球員
            .head.play_team 隊伍
            .head.play_event 事件
            .head.play_feedback 問題回報
          .play_content
            .play_item(
              :class="{ selected: currentPlayIndex == index }",
              v-for="(play, index) in playList",
              :key="index"
            )
              .content.play_play
                v-icon.play(x-small, color="white") mdi-play
              .content.play_time {{ play.video_start_at }}
              .content.play_player {{ play.player_name }}
              .content.play_team {{ dialogTeam }}
              .content.play_event {{ dialogEvent }}
              .content.play_feedback
                v-icon(small) mdi-alert-octagon
              .play_btn(@click="onPlayItemClick(play, index)")
              .feedback_btn(@click="onFeedbackBtnClick(play)")
  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
  dialog-feedback(
    :dialogFeedback="dialogFeedbackShow",
    :play="feedBackPlay",
    @closeFeedbackDialog="closeFeedbackDialog"
  )
  dialog-login(
    :dialogLogin="dialogLoginShow",
    @closeLoginDialog="closeLoginDialog",
    @loginSuccess="loginSuccess"
  )
</template>

<script>
import PlayersRankingCard from "@/components/league/PlayersRankingCard";
import RankingMark from "@/components/common/RankingMark";
import PercentageBar from "@/components/common/PercentageBar";
import StickyColumn from "@/components/common/Table/StickyColumn";
import PositionField from "@/components/common/PositionField";
import HighChartPolar from "@/components/common/HighChartPolar";
import Box from "@/components/common/Box";
import BottomHead from "@/components/common/Box/BottomHead";
import TopHead from "@/components/common/Box/TopHead";
import { getTeamRankingData } from "@/api/team";
import { getTeamSeasonData } from "@/api/team";
import { getPlayerSeasonData } from "@/api/player";
import { getPlayList } from "@/api/play";
import moment from "moment";
import DialogLoading from "@/components/dialog/DialogLoading";
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogFeedback from "@/components/dialog/DialogFeedback";
import DialogLogin from "@/components/dialog/DialogLogin";

export default {
  name: "LeagueTeamStatistics",
  components: {
    PlayersRankingCard,
    RankingMark,
    StickyColumn,
    PercentageBar,
    PositionField,
    HighChartPolar,
    DialogSuccess,
    DialogLoading,
    DialogFeedback,
    DialogLogin,
    Box,
    BottomHead,
    TopHead,
  },
  data() {
    return {
      league: this.$route.params.league,
      season: this.$route.params.season,
      teamID: this.$route.params.id,
      seasonTeamId: 0,
      bestTeamRankList: [],
      playerSeasonDataList: [],

      seasonInquiryType: 0,
      polarObject: {
        averageList: [],
        personalList: [],
        averagePercentList: [],
        personalPercentList: [],
        bestList: [],
      },

      teamSeasonQuarterData: [],
      teamSeasonWinLoseData: [],
      teamSeasonDateData: [],
      teamSeasonAllData: [],
      teamSeasonVSTeamData: [],

      shotPositionList: [],
      barRankingLists: [],
      playersRankingLists: [],
      isDialogShow: false,
      coverYT: true,
      playHeadList: ["球員", "隊伍", "事件", "時間", "播放"],
      isPlayAll: false,
      playAllIndex: 0,
      currentPlayIndex: null,
      dialogName: "",
      dialogTeam: "",
      dialogEvent: "",
      playList: [],

      feedBackPlay: null,

      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "",
      },

      dialogFeedbackShow: false,
      dialogLoginShow: false,
    };
  },
  watch: {
    "$route.params.season"() {
      this.season = this.$route.params.season;

      this.bestTeamRankList = [];
      this.playerSeasonDataList = [];
      this.polarObject = {
        averageList: [],
        personalList: [],
        averagePercentList: [],
        personalPercentList: [],
        bestList: [],
      };
      this.teamSeasonQuarterData = [];
      this.teamSeasonWinLoseData = [];
      this.teamSeasonDateData = [];
      this.teamSeasonAllData = {};
      this.teamSeasonVSTeamData = [];
      this.shotPositionList = [];
      this.barRankingLists = [];
      this.playersRankingLists = [];

      this.initData();
    },
    isDialogShow() {
      if (!this.isDialogShow) {
        this.$refs.youtube.player.stopVideo();
        this.dialogName = "";
        this.dialogTeam = "";
        this.dialogEvent = "";
        this.isPlayAll = false;
        this.playAllIndex = 0;
        this.currentPlayIndex = null;
        this.playList = [];
        this.coverYT = true;
      }
    },
  },
  computed: {
    YTPlayer() {
      return this.$refs.youtube.player;
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.getTeamRankingData();
      await this.getPlayerSeasonData();
      await this.getTeamSeasonAllData();
    },
    async getTeamRankingData() {
      let temp = {
        season_id: this.season,
        part: "info,ranking",
        ranking: 1,
      };
      await getTeamRankingData(temp).then((response) => {
        this.bestTeamRankList = response.data;
      });
    },
    async getPlayerSeasonData() {
      let temp = {
        season_id: this.season,
        part: "info,ranking",
        team_id: this.teamID,
      };
      await getPlayerSeasonData(temp).then((response) => {
        this.playerSeasonDataList = response.data;
        this.getPlayerRankingList();
      });
    },
    async getTeamSeasonAllData() {
      let temp = {
        season_id: this.season,
        part: "info,box,shot_position,ranking",
        team_id: this.teamID,
      };
      await getTeamSeasonData(temp).then((response) => {
        this.teamSeasonAllData = response.data.summary;
        this.teamSeasonDateData = response.data.season_type;
        this.teamSeasonQuarterData = response.data.quarters;
        this.teamSeasonWinLoseData = response.data.win_lose;
        this.teamSeasonVSTeamData = response.data.vs_team;
        this.shotPositionList = this.getShotPositionList(
          this.teamSeasonAllData.shot_position
        );
        this.seasonTeamId = this.teamSeasonAllData.info.season_team_id;
        this.getBarRankingList();
        this.getPolarObject();
      });
    },
    getRankText(rank) {
      if (rank == 1) {
        return "1st";
      } else if (rank == 2) {
        return "2nd";
      } else if (rank == 3) {
        return "3rd";
      } else {
        return rank + "th";
      }
    },
    getPlayerRankingList() {
      let pointsRanking = {
        type: "score",
        engTitle: "Points",
        title: "得分",
        rankings: this.bindPlayerRankingData("pts"),
      };
      this.playersRankingLists.push(pointsRanking);

      let reboundRanking = {
        type: "rebound",
        engTitle: "Rebound",
        title: "籃板",
        rankings: this.bindPlayerRankingData("reb"),
      };
      this.playersRankingLists.push(reboundRanking);

      let assistRanking = {
        type: "assist",
        engTitle: "Assist",
        title: "助攻",
        rankings: this.bindPlayerRankingData("ast"),
      };
      this.playersRankingLists.push(assistRanking);

      let stealRanking = {
        type: "steal",
        engTitle: "Steal",
        title: "抄截",
        rankings: this.bindPlayerRankingData("stl"),
      };
      this.playersRankingLists.push(stealRanking);

      let blockRanking = {
        type: "block",
        engTitle: "Block",
        title: "阻攻",
        rankings: this.bindPlayerRankingData("blk"),
      };
      this.playersRankingLists.push(blockRanking);

      let fieldGoalRanking = {
        type: "feildGoal",
        engTitle: "Feild Goal",
        title: "投籃命中率",
        percent: true,
        rankings: this.bindPlayerRankingData("fgp"),
      };
      this.playersRankingLists.push(fieldGoalRanking);
    },
    getShotPositionList(shotPosition) {
      let tempList = [];

      let PAINT = {
        name: "PAINT",
        fga: shotPosition.paint.fga,
        fgm: shotPosition.paint.fgm,
        percent: (shotPosition.paint.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(PAINT);

      let TOP_OF_THE_ARC_2_PTS = {
        name: "TOP_OF_THE_ARC_2_PTS",
        fga: shotPosition.top_2pt.fga,
        fgm: shotPosition.top_2pt.fgm,
        percent: (shotPosition.top_2pt.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(TOP_OF_THE_ARC_2_PTS);

      let TOP_OF_THE_ARC_3_PTS = {
        name: "TOP_OF_THE_ARC_3_PTS",
        fga: shotPosition.top_3pt.fga,
        fgm: shotPosition.top_3pt.fgm,
        percent: (shotPosition.top_3pt.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(TOP_OF_THE_ARC_3_PTS);

      let LEFT_BASELINE_2_PTS = {
        name: "LEFT_BASELINE_2_PTS",
        fga: shotPosition.left_baseline_2pt.fga,
        fgm: shotPosition.left_baseline_2pt.fgm,
        percent:
          (shotPosition.left_baseline_2pt.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(LEFT_BASELINE_2_PTS);

      let RIGHT_BASELINE_2_PTS = {
        name: "RIGHT_BASELINE_2_PTS",
        fga: shotPosition.right_baseline_2pt.fga,
        fgm: shotPosition.right_baseline_2pt.fgm,
        percent:
          (shotPosition.right_baseline_2pt.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(RIGHT_BASELINE_2_PTS);

      let LEFT_WING_2_PTS = {
        name: "LEFT_WING_2_PTS",
        fga: shotPosition.left_wing_2pt.fga,
        fgm: shotPosition.left_wing_2pt.fgm,
        percent: (shotPosition.left_wing_2pt.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(LEFT_WING_2_PTS);

      let RIGHT_WING_2_PTS = {
        name: "RIGHT_WING_2_PTS",
        fga: shotPosition.right_wing_2pt.fga,
        fgm: shotPosition.right_wing_2pt.fgm,
        percent:
          (shotPosition.right_wing_2pt.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(RIGHT_WING_2_PTS);

      let LEFT_BASELINE_3_PTS = {
        name: "LEFT_BASELINE_3_PTS",
        fga: shotPosition.left_baseline_3pt.fga,
        fgm: shotPosition.left_baseline_3pt.fgm,
        percent:
          (shotPosition.left_baseline_3pt.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(LEFT_BASELINE_3_PTS);

      let RIGHT_BASELINE_3_PTS = {
        name: "RIGHT_BASELINE_3_PTS",
        fga: shotPosition.right_baseline_3pt.fga,
        fgm: shotPosition.right_baseline_3pt.fgm,
        percent:
          (shotPosition.right_baseline_3pt.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(RIGHT_BASELINE_3_PTS);

      let LEFT_WING_3_PTS = {
        name: "LEFT_WING_3_PTS",
        fga: shotPosition.left_wing_3pt.fga,
        fgm: shotPosition.left_wing_3pt.fgm,
        percent: (shotPosition.left_wing_3pt.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(LEFT_WING_3_PTS);

      let RIGHT_WING_3_PTS = {
        name: "RIGHT_WING_3_PTS",
        fga: shotPosition.right_wing_3pt.fga,
        fgm: shotPosition.right_wing_3pt.fgm,
        percent:
          (shotPosition.right_wing_3pt.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(RIGHT_WING_3_PTS);

      return tempList;
    },
    bindPlayerRankingData(type) {
      let tempList;
      let rankingList;
      switch (type) {
        case "pts":
          tempList = this.playerSeasonDataList
            .sort(function (a, b) {
              return a.ranking.avg_pts.ranking - b.ranking.avg_pts.ranking;
            })
            .slice(0, 3);
          rankingList = [];
          for (let index in tempList) {
            let item = tempList[index];
            let rankingdata = {
              info: item.info,
              ranking: item.ranking.avg_pts,
            };
            rankingList.push(rankingdata);
          }
          return rankingList;
        case "reb":
          tempList = this.playerSeasonDataList
            .sort(function (a, b) {
              return a.ranking.avg_reb.ranking - b.ranking.avg_reb.ranking;
            })
            .slice(0, 3);
          rankingList = [];
          for (let index in tempList) {
            let item = tempList[index];
            let rankingdata = {
              info: item.info,
              ranking: item.ranking.avg_reb,
            };
            rankingList.push(rankingdata);
          }
          return rankingList;
        case "ast":
          tempList = this.playerSeasonDataList
            .sort(function (a, b) {
              return a.ranking.avg_ast.ranking - b.ranking.avg_ast.ranking;
            })
            .slice(0, 3);
          rankingList = [];
          for (let index in tempList) {
            let item = tempList[index];
            let rankingdata = {
              info: item.info,
              ranking: item.ranking.avg_ast,
            };
            rankingList.push(rankingdata);
          }
          return rankingList;
        case "stl":
          tempList = this.playerSeasonDataList
            .sort(function (a, b) {
              return a.ranking.avg_stl.ranking - b.ranking.avg_stl.ranking;
            })
            .slice(0, 3);
          rankingList = [];
          for (let index in tempList) {
            let item = tempList[index];
            let rankingdata = {
              info: item.info,
              ranking: item.ranking.avg_stl,
            };
            rankingList.push(rankingdata);
          }
          return rankingList;
        case "blk":
          tempList = this.playerSeasonDataList
            .sort(function (a, b) {
              return a.ranking.avg_blk.ranking - b.ranking.avg_blk.ranking;
            })
            .slice(0, 3);
          rankingList = [];
          for (let index in tempList) {
            let item = tempList[index];
            let rankingdata = {
              info: item.info,
              ranking: item.ranking.avg_blk,
            };
            rankingList.push(rankingdata);
          }
          return rankingList;
        case "fgp":
          tempList = this.playerSeasonDataList
            .sort(function (a, b) {
              return (
                a.ranking.avg_fg_percent.ranking -
                b.ranking.avg_fg_percent.ranking
              );
            })
            .slice(0, 3);
          rankingList = [];
          for (let index in tempList) {
            let item = tempList[index];
            let rankingdata = {
              info: item.info,
              ranking: item.ranking.avg_fg_percent,
            };
            rankingList.push(rankingdata);
          }
          return rankingList;
      }
    },
    getPolarObject() {
      this.polarObject.personalList.push(
        Number(this.teamSeasonAllData.ranking.avg_pts.value.toFixed(1))
      );
      this.polarObject.personalList.push(
        Number(this.teamSeasonAllData.ranking.avg_reb.value.toFixed(1))
      );
      this.polarObject.personalList.push(
        Number(this.teamSeasonAllData.ranking.avg_ast.value.toFixed(1))
      );
      this.polarObject.personalList.push(
        Number(
          (this.teamSeasonAllData.ranking.avg_ft_percent.value * 100).toFixed(1)
        )
      );
      this.polarObject.personalList.push(
        Number(
          (
            this.teamSeasonAllData.ranking.avg_three_pt_fg_percent.value * 100
          ).toFixed(1)
        )
      );
      this.polarObject.personalList.push(
        Number(
          (
            this.teamSeasonAllData.ranking.avg_two_pt_fg_percent.value * 100
          ).toFixed(1)
        )
      );

      this.polarObject.bestList.push(
        Number(this.bestTeamRankList.avg_pts[0].ranking.value.toFixed(1))
      );
      this.polarObject.bestList.push(
        Number(this.bestTeamRankList.avg_reb[0].ranking.value.toFixed(1))
      );
      this.polarObject.bestList.push(
        Number(this.bestTeamRankList.avg_ast[0].ranking.value.toFixed(1))
      );
      this.polarObject.bestList.push(
        Number(
          (this.bestTeamRankList.avg_ft_percent[0].ranking.value * 100).toFixed(
            1
          )
        )
      );
      this.polarObject.bestList.push(
        Number(
          (
            this.bestTeamRankList.avg_three_pt_fg_percent[0].ranking.value * 100
          ).toFixed(1)
        )
      );
      this.polarObject.bestList.push(
        Number(
          (
            this.bestTeamRankList.avg_two_pt_fg_percent[0].ranking.value * 100
          ).toFixed(1)
        )
      );

      this.polarObject.personalPercentList.push(
        Number(
          (
            (this.teamSeasonAllData.ranking.avg_pts.value /
              this.bestTeamRankList.avg_pts[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.personalPercentList.push(
        Number(
          (
            (this.teamSeasonAllData.ranking.avg_reb.value /
              this.bestTeamRankList.avg_reb[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.personalPercentList.push(
        Number(
          (
            (this.teamSeasonAllData.ranking.avg_ast.value /
              this.bestTeamRankList.avg_ast[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.personalPercentList.push(
        Number(
          (
            (this.teamSeasonAllData.ranking.avg_ft_percent.value /
              this.bestTeamRankList.avg_ft_percent[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.personalPercentList.push(
        Number(
          (
            (this.teamSeasonAllData.ranking.avg_three_pt_fg_percent.value /
              this.bestTeamRankList.avg_three_pt_fg_percent[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.personalPercentList.push(
        Number(
          (
            (this.teamSeasonAllData.ranking.avg_two_pt_fg_percent.value /
              this.bestTeamRankList.avg_two_pt_fg_percent[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );

      this.polarObject.averageList.push(
        Number(this.teamSeasonAllData.ranking.avg_pts.avg.toFixed(1))
      );
      this.polarObject.averageList.push(
        Number(this.teamSeasonAllData.ranking.avg_reb.avg.toFixed(1))
      );
      this.polarObject.averageList.push(
        Number(this.teamSeasonAllData.ranking.avg_ast.avg.toFixed(1))
      );
      this.polarObject.averageList.push(
        Number(
          (this.teamSeasonAllData.ranking.avg_ft_percent.avg * 100).toFixed(1)
        )
      );
      this.polarObject.averageList.push(
        Number(
          (
            this.teamSeasonAllData.ranking.avg_three_pt_fg_percent.avg * 100
          ).toFixed(1)
        )
      );
      this.polarObject.averageList.push(
        Number(
          (
            this.teamSeasonAllData.ranking.avg_two_pt_fg_percent.avg * 100
          ).toFixed(1)
        )
      );

      this.polarObject.averagePercentList.push(
        Number(
          (
            (this.teamSeasonAllData.ranking.avg_pts.avg /
              this.bestTeamRankList.avg_pts[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.averagePercentList.push(
        Number(
          (
            (this.teamSeasonAllData.ranking.avg_reb.avg /
              this.bestTeamRankList.avg_reb[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.averagePercentList.push(
        Number(
          (
            (this.teamSeasonAllData.ranking.avg_ast.avg /
              this.bestTeamRankList.avg_ast[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.averagePercentList.push(
        Number(
          (
            (this.teamSeasonAllData.ranking.avg_ft_percent.avg /
              this.bestTeamRankList.avg_ft_percent[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.averagePercentList.push(
        Number(
          (
            (this.teamSeasonAllData.ranking.avg_three_pt_fg_percent.avg /
              this.bestTeamRankList.avg_three_pt_fg_percent[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.averagePercentList.push(
        Number(
          (
            (this.teamSeasonAllData.ranking.avg_two_pt_fg_percent.avg /
              this.bestTeamRankList.avg_two_pt_fg_percent[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
    },
    getBarRankingList() {
      let offensive = {
        type: "offensive",
        title: "進攻",
        rankings: [
          {
            title: "得分",
            ranking: this.teamSeasonAllData.ranking.avg_pts.ranking,
            value: Number(
              this.teamSeasonAllData.ranking.avg_pts.value.toFixed(1)
            ),
            deno: Number(
              this.bestTeamRankList.avg_pts[0].ranking.value.toFixed(1)
            ),
          },
          {
            title: "助攻",
            ranking: this.teamSeasonAllData.ranking.avg_ast.ranking,
            value: Number(
              this.teamSeasonAllData.ranking.avg_ast.value.toFixed(1)
            ),
            deno: Number(
              this.bestTeamRankList.avg_ast[0].ranking.value.toFixed(1)
            ),
          },
          {
            title: "失誤",
            ranking: this.teamSeasonAllData.ranking.avg_tov.ranking,
            value: Number(
              this.teamSeasonAllData.ranking.avg_tov.value.toFixed(1)
            ),
            deno: Number(
              this.bestTeamRankList.avg_tov[0].ranking.value.toFixed(1)
            ),
          },
        ],
      };
      this.barRankingLists.push(offensive);

      let defensive = {
        type: "defensive",
        title: "防守",
        rankings: [
          {
            title: "籃板",
            ranking: this.teamSeasonAllData.ranking.avg_reb.ranking,
            value: Number(
              this.teamSeasonAllData.ranking.avg_reb.value.toFixed(1)
            ),
            deno: Number(
              this.bestTeamRankList.avg_reb[0].ranking.value.toFixed(1)
            ),
          },
          {
            title: "阻攻",
            ranking: this.teamSeasonAllData.ranking.avg_blk.ranking,
            value: Number(
              this.teamSeasonAllData.ranking.avg_blk.value.toFixed(1)
            ),
            deno: Number(
              this.bestTeamRankList.avg_blk[0].ranking.value.toFixed(1)
            ),
          },
          {
            title: "抄截",
            ranking: this.teamSeasonAllData.ranking.avg_stl.ranking,
            value: Number(
              this.teamSeasonAllData.ranking.avg_stl.value.toFixed(1)
            ),
            deno: Number(
              this.bestTeamRankList.avg_stl[0].ranking.value.toFixed(1)
            ),
          },
        ],
      };
      this.barRankingLists.push(defensive);

      let shot = {
        type: "shot",
        title: "投籃",
        rankings: [
          {
            title: "投籃%",
            percent: true,
            ranking: this.teamSeasonAllData.ranking.avg_fg_percent.ranking,
            value: this.teamSeasonAllData.ranking.avg_fg_percent.value,
            deno: this.bestTeamRankList.avg_fg_percent[0].ranking.value,
          },
          {
            title: "三分%",
            percent: true,
            ranking:
              this.teamSeasonAllData.ranking.avg_three_pt_fg_percent.ranking,
            value: this.teamSeasonAllData.ranking.avg_three_pt_fg_percent.value,
            deno: this.bestTeamRankList.avg_three_pt_fg_percent[0].ranking
              .value,
          },
          {
            title: "三分得分",
            ranking: this.teamSeasonAllData.ranking.avg_three_pts.ranking,
            value: Number(
              this.teamSeasonAllData.ranking.avg_three_pts.value.toFixed(1)
            ),
            deno: Number(
              this.bestTeamRankList.avg_three_pts[0].ranking.value.toFixed(1)
            ),
          },
        ],
      };
      this.barRankingLists.push(shot);

      let freeThrow = {
        type: "shot",
        title: "罰球",
        rankings: [
          {
            title: "罰球%",
            percent: true,
            ranking: this.teamSeasonAllData.ranking.avg_ft_percent.ranking,
            value: this.teamSeasonAllData.ranking.avg_ft_percent.value,
            deno: this.bestTeamRankList.avg_ft_percent[0].ranking.value,
          },
          {
            title: "罰球得分",
            ranking: this.teamSeasonAllData.ranking.avg_ft_pts.ranking,
            value: Number(
              this.teamSeasonAllData.ranking.avg_ft_pts.value.toFixed(1)
            ),
            deno: Number(
              this.bestTeamRankList.avg_ft_pts[0].ranking.value.toFixed(1)
            ),
          },
        ],
      };
      this.barRankingLists.push(freeThrow);
    },
    getQuarterText(count) {
      if (count == 1) {
        return "第一節";
      } else if (count == 2) {
        return "第二節";
      } else if (count == 3) {
        return "第三節";
      } else if (count == 4) {
        return "第四節";
      } else {
        return "OT" + (count - 4);
      }
    },
    teamClick(game) {
      this.$router.push({
        name: "LeagueTeam",
        params: {
          league: this.league,
          season: this.season,
          id: game.info.split_info.team_id,
        },
      });
      this.$emit("getTeamData", game.info.split_info.team_id);
    },
    dataClick(game, stat, event) {
      this.isPlayAll = false;
      this.playList = [];
      this.dialogName = game.info.name;
      this.dialogTeam = game.info.name;
      this.dialogEvent = event;
      this.getPlayList(stat);
    },
    getPlayList(stat) {
      this.showLoadingDialog("載入中...");

      let params = {
        season_team_id: this.seasonTeamId,
        group_by: "TEAM",
        stat: stat,
      };

      let data = {};

      getPlayList(params, data)
        .then((response) => {
          this.closeLoadingDialog();
          this.playList = response.data;
          if (this.playList && this.playList.length > 0) {
            this.isDialogShow = true;
          }
        })
        .catch(() => {
          this.closeLoadingDialog();
        });
    },
    positionClick(positionData) {
      this.isPlayAll = false;
      this.playList = [];
      this.dialogName = this.teamSeasonAllData.info.name;
      this.dialogTeam = this.teamSeasonAllData.info.name;
      this.dialogEvent = positionData.dialogEvent;
      this.getPlayDataByPosition(positionData.position);
    },
    getPlayDataByPosition(position) {
      this.showLoadingDialog("載入中...");
      let query_param = {
        group_by: "TEAM",
        season_team_id: this.seasonTeamId,
      };

      let data = { shot_position: position };

      getPlayList(query_param, data)
        .then((response) => {
          this.closeLoadingDialog();
          this.playList = response.data;
          if (this.playList && this.playList.length > 0) {
            this.isDialogShow = true;
          }
        })
        .catch(() => {
          this.closeLoadingDialog();
        });
    },
    playAllClick() {
      this.coverYT = false;
      this.isPlayAll = true;
      this.playAllIndex = 0;
      this.currentPlayIndex = this.playAllIndex;
      if (this.playList && this.playList.length > 0) {
        this.playVideo(this.playList[this.playAllIndex]);
      }
    },
    onYTReady() {
      if (this.playList && this.playList.length > 0) {
        this.YTPlayer.cueVideoById({
          videoId: this.playList[0].game__youtube_ids__0,
        });
      }
    },
    onYTEnd(e) {
      if (this.isPlayAll && e.getVideoLoadedFraction() > 0) {
        if (this.playList && this.playList.length > 0) {
          if (this.playAllIndex + 1 < this.playList.length) {
            this.playAllIndex = this.playAllIndex + 1;

            this.currentPlayIndex = this.playAllIndex;

            this.playVideo(this.playList[this.playAllIndex]);
          } else {
            this.isPlayAll = false;
            this.playAllIndex = 0;
          }
        }
      }
    },
    onPlayItemClick(play, index) {
      this.coverYT = false;
      this.currentPlayIndex = index;
      this.playVideo(play);
    },
    onFeedbackBtnClick(play) {
      play.league = this.league;
      play.team = this.dialogTeam;
      this.feedBackPlay = play;
      this.showFeedbackDialog();
      // if (!this.$store.state.user.accessToken) {
      //   this.showLoginDialog();
      // } else {
      //   this.showFeedbackDialog();
      // }
    },
    playVideo(play) {
      this.scrollToTop();
      var startSeconds =
        play.result == "DRAW FOUL"
          ? moment.duration(play.video_end_at).asSeconds()
          : moment.duration(play.video_start_at).asSeconds();
      this.YTPlayer.loadVideoById({
        videoId: play.game__youtube_ids__0,
        startSeconds: startSeconds - play.video_play_duration,
        endSeconds: moment.duration(play.video_start_at).asSeconds() + 2,
      });
    },
    scrollToTop() {
      const c = this.$refs.content.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(this.scrollToTop);
        this.$refs.content.scrollTo(0, c - c / 10);
      }
    },
    playerClick(player) {
      this.$router.push({
        name: "LeaguePlayer",
        params: {
          season: this.leagueData.seasons[this.seasonInquiryType].id,
          id: player.info.id,
        },
      });
    },
    loginSuccess() {
      this.closeLoginDialog();
    },
    showFeedbackDialog() {
      this.dialogFeedbackShow = true;
    },
    closeFeedbackDialog() {
      this.dialogFeedbackShow = false;
    },
    showLoginDialog() {
      this.dialogLoginShow = true;
    },
    closeLoginDialog() {
      this.dialogLoginShow = false;
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    showLoadingDialog(text) {
      this.dialogLoadingData.text = text;
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/dialog_play_video";

.img_circle {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.ranking_list {
  li {
    height: 42px;
    border-bottom: #d2d2d2 1px solid;

    &:first-child {
      color: white;
      background-color: #002442;
      border-bottom: none;

      .number {
        text-align: right;
        flex-grow: 7;
      }

      .player {
        flex-grow: 5;
        margin-left: 10px;
      }

      .score {
        font-family: Helvetica;
        font-size: 24px;
        font-style: italic;
        font-weight: bold;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    .ranking {
      flex-grow: 0;
    }

    .number {
      flex-grow: 3;
      text-align: center;
    }

    .player {
      flex-grow: 5;
    }

    .score {
      flex-grow: 4;
    }
  }
}

.ranking_league_bar {
  .title {
    flex-shrink: 0;
    min-width: 74px;
  }

  .ranking_mark {
    margin-right: 16px;
    flex-shrink: 0;
  }
}

.table_container {
  margin-top: 100px;
}

input,
textarea,
select {
  background: #ffffff;
  border: 1px solid #487aa4;
  // @include font(16px, #a473e0, 300);
}

input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  background: url("~@/assets/img/search.png") no-repeat right center;
  background-position: calc(100% - 10px) center;
}

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  background: url("~@/assets/img/arrow_bottom_s.png") no-repeat right center;
  background-position: calc(100% - 40px) center;
}

.theme--light.v-sheet {
  li {
    .ranking {
      color: $lightGray;
    }

    &:first-child {
      .ranking {
        color: white;
      }
    }
  }
}
</style>
